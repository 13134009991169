var btn_prev = document.querySelector('.seven__button .prev');
var btn_next = document.querySelector('.seven__button .next');

var images = document.querySelectorAll('.seven__box');
var i = 0;

window.onload = function(){ btn_prev.onclick = function(){
    images[i].className = 'seven__box';
    i--;
    if (i < 0){
        i = images.length - 1;
    }
    images[i].className = 'showed';
}};

window.onload = function(){ btn_next.onclick = function(){
    images[i].className = 'seven__box';
    i++;
    if (i>=images.length){
        i=0;
    }
    images[i].className = 'showed';
}};

var linkNav = document.querySelectorAll('[href^="#nav"]'),
    V = 2;  // скорость, может иметь дробное значение через точку
for (var i = 0; i < linkNav.length; i++) {
    linkNav[i].onclick = function(){
        var w = window.pageYOffset,
            hash = this.href.replace(/[^#]*(.*)/, '$1');
        t = document.querySelector(hash).getBoundingClientRect().top,
            start = null;
        requestAnimationFrame(step);
        function step(time) {
            if (start === null) start = time;
            var progress = time - start,
                r = (t < 0 ? Math.max(w - progress/V, w + t) : Math.min(w + progress/V, w + t));
            window.scrollTo(0,r);
            if (r != w + t) {requestAnimationFrame(step)} else {location.hash = hash}
        }
        return false;
    }
}
$(window).ready(function () {
    $('.modal__close').click(function(){
        $('.modal').toggleClass('upp');
    });

    $('#modal').click(function(){
        event.preventDefault();
        $('.modal').toggleClass('upp');
    });
     $('#modal2').click(function(){
        event.preventDefault();
        $('.modal').toggleClass('upp');
    });
      $('#modal3').click(function(){
        event.preventDefault();
        $('.modal').toggleClass('upp');
    });
    $('#modal4').click(function(){
        event.preventDefault();
        $('.modal').toggleClass('upp');
    });
     $('#modal5').click(function(){
        event.preventDefault();
        $('.modal').toggleClass('upp');
    });
      $('#modal6').click(function(){
        event.preventDefault();
        $('.modal').toggleClass('upp');
    });
    $(document).keyup(function(e) {
         if (e.keyCode == 27) { 
            $('.modal').toggleClass('upp');
        }
    });
    $('#one').on('click', function(){
        $('html, body').animate({
            scrollTop: $("#home").offset().top
        }, 2000);
        event.preventDefault();
    });
     $('#two').on('click', function(){
        $('html, body').animate({
            scrollTop: $("#tww").offset().top
        }, 2000);
        event.preventDefault();
    });
     $('#three').on('click', function(){
        $('html, body').animate({
            scrollTop: $("#tw").offset().top
        }, 2000);
        event.preventDefault();
    });
      $('#four').on('click', function(){
        $('html, body').animate({
            scrollTop: $("#cont").offset().top
        }, 2000);
        event.preventDefault();
    });
});
